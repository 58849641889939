import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'

export default () => (
  <section id="demo" className="page-section">
    <Container>
      <div className="heading-block center">
        <h2>Demo</h2>
        <Link to="/demo">Start the app</Link>
        <div className="heading-block-adj center" />
        {/*        <iframe
          title="app"
          width="100%"
          height="100vh"
          style={{
            width: '100%',
            height: '2000px',
          }}
        >*/}
        {/*</iframe>*/}
        <div className="clear" />
      </div>
    </Container>
  </section>
)
