import { useState, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import useDebouncedCallback from 'react-use/lib/useDebounce'

const DELAY = 3000

export default () => {
  const data = useStaticQuery(graphql`
    query SiteEmailCompsQuery {
      site {
        siteMetadata {
          emailComps
        }
      }
    }
  `)

  const emailComps = data.site.siteMetadata.emailComps

  const [state, setState] = useState({ email: ['', ''], active: false })

  useDebouncedCallback(
    () => {
      setState({ email: emailComps.join(''), active: true })
    },
    DELAY,
    []
  )

  const email = useMemo(() => (state.active ? `${state.email}` : ''), [state])

  return { active: state.active, email }
}
