import React from 'react'

export default ({ email }) => {
  if (!email) {
    return null
  }
  return (
    <a href={`mailto:${email}?Subject=makenode%20feedback`} target="_top">
      {email}
    </a>
  )
}
