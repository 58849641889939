import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

const Section = styled.section`
  color: black;
  max-width: 35em;
  margin: auto;
`

const Strong = styled.strong`
  font-weight: 900;
`

const Li = styled.li`
  margin-left: 20px;
  list-style-type: disc;
`

export default () => (
  <Section id="about" className="page-section">
    <Container>
      <div className="heading-block center">
        <h2>
          <span>Your input</span> helps.
        </h2>
      </div>
      <div /*className="center"*/>
        <h3>What</h3>
        <p>
          I am creating a tool to{' '}
          <Strong>
            grow ideas from first thought to well-developed concept
          </Strong>
          .
        </p>
        <h3>Why is it hard?</h3>
        <p>
          I am not aware of any tool perfectly targeted for growing new ideas
          into detailed, ready-for-execution concepts. Usually, I just look for
          helpful templates but otherwise don't worry too much about a dedicated
          tool for this.
        </p>
        <p>
          But it doesn't feel right. I would love a tool that lets me
          <Strong> write freely</Strong> and
          <Strong> develop structure</Strong> – both at the same time.
        </p>
        <p>
          <Strong>Writing freely</Strong> is important to let thoughts flow from
          the mind into the fingers. Otherwise we can lose important thoughts
          while we try to match them against a fixed structure.
        </p>
        <p>
          <Strong>Developing structure</Strong> is important to grow our idea.
          We categorize our thoughts into separate sections such as "problem",
          "solution", maybe sub-sections such as "out of scope". This lets us
          look at the idea from various angles.
        </p>
        <p>
          But structure needs to be <Strong> efficient</Strong>: As we iterate
          our idea, the different sections get out of sync. So we want to easily
          find other sections from our current location, then zip back and forth
          to align things.
        </p>
        {/*<blockquote>
          For example, lets say you try to find a solution to a problem and
          early in the process you already find some useful limits to the
          problem's scope which you don't want to cross. Then, if later on you
          get carried away thinking about possible solutions and cross that
          scope boundary you set yourself, it will be important to easily figure
          this out and think again.
        </blockquote>*/}

        <div className="heading-block center" />
        <p>
          For me, this is where ideas often fail to grow well in practice. Our
          thoughts carry us to new places. And soon, it's too much effort to
          update those useful aims and constraints we had put down in other
          sections.
        </p>
        <p>
          We may even end up adding new thoughts to wrong places, because we
          assume that the right place will be hard to locate, or worse, already
          messy.
        </p>
        <h3>The right tool</h3>
        <p>
          I think a good tool to grow ideas needs to get the following right:
        </p>
        <ul>
          <Li>It allows writing ideas in a free-flowing way.</Li>
          <Li>It is easy to add structure to content.</Li>
          <Li>It is easy to modify and remove ("unpack") structure later.</Li>
          {/* (I actually think the best structure is a tree because our minds like to categorize things.)*/}
          <Li>Understanding and navigating the structure is effortless.</Li>
        </ul>
        <h3>Slides, mindmaps, wikis, my demo</h3>
        <p>
          I believe many people grow ideas in tools that provide little or no
          structure. We like these tools because they serve us for our first
          burst of creativity. But they let us down later, when it's time to
          match up the different parts of our idea.
        </p>
        <h4>Slides</h4>
        <p>
          A lot of people like slide decks, empty or with a template. Slide
          decks give us a free writing experience on each slide, except when
          we're fighting page margins. Slides give our idea some structure. But:
          we can only navigate the top menu. As soon as slides have subitems, or
          top level content requires more than one slide, locating content
          becomes difficult.
        </p>
        <h4>Mindmaps</h4>
        <p>
          Mindmaps are highly structured. They are great when few words are
          enough. To me they serve initial ideation best. When our ideas grow
          and we want to write out details, mindmaps can get unwieldy.
        </p>
        <h4>Wikis</h4>
        <p>
          Wikis also support deep structure. But I wouldn't use a wiki to grow
          ideas. My main issue is the bureaucracy involved in changing existing
          structure. Also, authors need to decide about what structure to
          display on one page, and where to start a new one – I would prefer to
          keep this flexible. Wikis seem to work best when ideas are already
          structured in our mind.
        </p>
        <h4>Other tools</h4>
        <p>
          I'm not going into filesystems and documents here because for growing
          ideas I think they're similar to wikis and slides, but less suitable.
        </p>
        <h4>This demo</h4>
        <p>
          I'm on the search for a user experience that is perfectly targeted at
          growing ideas. I want to put a focus on combining free writing with
          easily changing structure.
        </p>
        <p>
          The demo below is a first iteration. It has a mindmap and a "tree
          notepad" layout. It's still far from a nice solution though.
        </p>
        <p>
          I moderately like the notepad, but it looks a bit dense and doesn't
          give much "free writing" experience. Also it is still missing usable
          navigation.
          <br />I chose cards over simple indenting because it seemed easier to
          understand multiple nesting levels.
        </p>
        <h4>My plans</h4>
        <ul>
          <Li>
            I'm planning to implement a <Strong>slide-like layout</Strong> but
            with easy navigation on nested structures.
          </Li>
          <Li>
            <p>
              There will be a back-end with a <Strong>collaboration </Strong>
              feature that I am developing. The feature has commits and
              branching similar to git, but has a different focus: I want it to
              be easy for peers to propose structural changes (along with the
              typical inline changes that many tools support).
            </p>
            <p>
              I want to make it easy to review many independent suggestions even
              if they conflict. I hope that this opens a space for reflection
              and negotiation. It should be possible to allow forking ideas into
              own variants that stay partially synced.
            </p>
          </Li>
        </ul>
        <h4>...and your input</h4>
        <p>
          But to achieve any of it, I need lots of feedback – I would appreciate
          yours!
        </p>
        <p>
          How do you currently grow new ideas into concepts? Did I miss your
          favorite tool? What's your feedback on any aspect of this idea?
        </p>
        {/*<p>
          And if you're interested beyond feedback and are skilled in an area
          that I'm obviously lacking, please let me know. (For my part that
          could be any number of things from business development to design.)
        </p>
        <p>
          If you have a capable friend who believes in the rule of evolution,
          and that one day there could be a wise form of anarchy that could
          actually hold our societies together – send them this link please. 😉
          </p>*/}
        <p>Give it a try:</p>
        <div className="heading-block-adj center">
          <p>&nbsp;</p>
        </div>
        <div className="clear" />
      </div>
      <div className="clear" />
    </Container>
  </Section>
)

/*
file systems:
Their fitness for idea development is similar to wikis, but with less capability for
navigation. Also, I miss the convenience of adding a annotation to a
file, or storing a mix of urls and files as equal siblings.
*/
