import React from 'react'

import Layout from '../components/Layout'
import LayoutHome from '../components/LayoutHome'
import SEO from '../components/Seo'
import Lead from '../components/Lead'
import ContentSection from '../components/ContentSection'
import { navigate } from '@reach/router'

const navigator = link => e => {
  e.preventDefault()
  navigate(link)
}

export default () => (
  <Layout>
    <LayoutHome>
      <SEO title="makenode" />
      <a className="anchor" name="home" onClick={navigator('#home')} />
      <Lead />
      <ContentSection />
    </LayoutHome>
  </Layout>
)
