import React from 'react'
import AboutMakenodeSection from './AboutMakenodeSection'
import DemoSection from './DemoSection'
import FeedbackSection from './FeedbackSection'

export default () => (
  <section id="content" style={{ marginBottom: 0 }}>
    <div className="content-wrap">
      <a className="anchor" name="about" />
      <AboutMakenodeSection />
      <a className="anchor" name="demo" />
      <DemoSection />
      <a className="anchor" name="feedback" />
      <FeedbackSection />
    </div>
  </section>
)
