import React from 'react'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'

import landingImg from '../images/landing-towers.jpg'

export default () => (
  <section id="slider">
    <ParallaxProvider>
      <Parallax y={[-71, 71]} tag="figure">
        <div
          className="swiper-slide dark swiper-slide-active dark"
          style={{
            backgroundImage: `url(${landingImg})`,
            // backgroundColor: '#383838',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: 730,
            width: '100%',
          }}
        >
          <div
            className="slider-caption no-disappear slider-caption-center"
            style={{
              left: 0,
              transform: 'translateY(0px)',
              opacity: 1,
              top: 215,
            }}
          >
            <h2
              data-caption-animate="fadeInUp"
              className="fadeInUp animated"
              style={{
                color: 'white',
                backgroundColor: 'rgba(38, 38, 38, 0.3)',
                boxShadow: '0 0 35px 25px rgba(38, 38, 38, 0.3)',
              }}
            >
              <span style={{ color: 'white' }}>How to grow ideas?</span>
            </h2>
            <p
              data-caption-animate="fadeInUp"
              data-caption-delay={200}
              className="fadeInUp animated no-disappear"
            >
              <span
                style={{
                  backgroundColor: 'rgba(38, 38, 38, 0.3)',
                }}
              >
                {/*From Thought to Concept.*/}
              </span>
            </p>
          </div>
        </div>
      </Parallax>
    </ParallaxProvider>
  </section>
)
