import React from 'react'
import { Container } from 'react-bootstrap'
import useEmail from '../hooks/useEmail'

import EmailLink from './EmailLink'

export default () => {
  const { active, email } = useEmail()

  return (
    <section id="contact" className="page-section topmargin-lg">
      <div className="heading-block title-center">
        <h2>What do you think?</h2>
        {/*<span>Send me feedback using the below form</span>*/}
      </div>
      <Container>
        {/* Contact Form
            ============================================= */}
        <div className="col_full col_last">
          <div>
            <div className="fancy-title title-dotted-border">
              <h3>Send a message</h3>
              {active && (
                <div>
                  <small>
                    Or email <EmailLink email={email} />.
                  </small>
                </div>
              )}
            </div>
            <div className="contact-widget">
              <div className="contact-form-result" />
              <form
                className="nobottommargin"
                id="template-contactform"
                name="template-contactform"
                action={`https://formspree.io/${email}`}
                method="post"
              >
                <div className="form-process" />
                <div className="col_one_third">
                  <label htmlFor="template-contactform-email">
                    Your Email {/*<small>*</small>*/}
                  </label>
                  <input
                    type="email"
                    id="template-contactform-email"
                    name="template-contactform-email"
                    defaultValue=""
                    className="email sm-form-control"
                  />
                </div>
                <div className="clear" />
                {/* <div className="col_full"> */}
                <div className="col_two_third">
                  <label htmlFor="template-contactform-message">
                    Message <small>*</small>
                  </label>
                  <textarea
                    className="required sm-form-control"
                    id="template-contactform-message"
                    name="template-contactform-message"
                    rows={6}
                    cols={30}
                    defaultValue={''}
                  />
                </div>
                <div className="col_full">
                  <button
                    disabled={!active}
                    className="button button-3d nomargin"
                    type="submit"
                    id="template-contactform-submit"
                    name="template-contactform-submit"
                    value="submit"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Contact Form End */}
      </Container>
    </section>
  )
}

// (Also, if you believe that decision-making in our world
//   should be more distributed and you have a skill that I'm obviously lacking,
//   I'd like to hear from you in any case! Or forward the link in case you have a friend who
//   believes that anarchy and a well-organized society can be matching concepts.)

// title: What tool are you using to develop ideas into robust concepts? What are you missing? Please let me know!
// I promise I will come back with updates and implement suggestions if I receive enough useful feedback.
// but if you support me with feedback, I promise I'll have the courage to dig in again
// and follow-up with <a better layout.> <next steps> <further improvements>.

// - how does it resonate with you? Do you have specific types of concepts where you encounter that pain a lot?
// - kritik an meinen Ideen?
// Are you interested in that problem space?

// How do you develop new ideas from first thought to perfect concept?
